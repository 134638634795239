import React from 'react'
import { useGetProductByIdQuery } from "../../redux/slice/ProductsApiSlice"
import { useParams } from "react-router-dom"
import ViewProduct from "../../components/shared/admin/components/ViewProduct"

const ViewProductPage = () => {
    const{id}=useParams()
    const { data: product, isError, isLoading, isSuccess, error} = useGetProductByIdQuery(id)
    let content
  
    if (isLoading) {
      content = (
          <div className="flex justify-center items-center h-full">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
          </div>
      );
  }
  
    if (isError) content = <p>{product?.result?.message}</p>
    if (isSuccess&& product?.result){
      content=  <ViewProduct saree={product?.result}  />
    }
    return (
      <div className="max-w-full md:mx-20 md:my-10 border-2 border-gray-300 md:p-4">
      <h5 className="h5-bold mb-4">Product Details</h5>
       {content}
    </div>
    )
}





export default ViewProductPage
