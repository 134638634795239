
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../ui/table";
import React from "react";
import CardComponent from "../Detail/CardComponent";
import { sareeData } from "@/react-app-env";
import { DataTablePagination } from "./TablePagination";


interface DataTableProps<TData extends sareeData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}


export function DataTable<TData extends sareeData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });


  return (
    <div className="flex flex-col md:my-5">
      <div>
        <Table>
          <TableBody className="grid lg:grid-cols-2 xl:grid-cols-3 grid-cols-1 md:grid-cols-1">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                    <TableCell key={row.id} >
                      {row.original && "id" in row.original ? (
                        <CardComponent key={row.original.id} shopbyprice={row.original as sareeData} />
                      ) : (
                        <span>Some other component</span>
                      )}
                    </TableCell>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center font-semibold text-lg">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div>
      {table.getRowModel().rows?.length ? <DataTablePagination table={table} />:""}
      </div>
    </div>
  );
}


