
import { Card } from "../../ui/card";
import { MdCurrencyRupee } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft, FaAngleRight, FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import { BsCurrencyDollar } from "react-icons/bs";
import { IMGURL } from "../../../constants/appConfig"
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { Link } from "react-router-dom";
import { sareeData } from "@/react-app-env";
import { useGetRecentProductsQuery } from "../../../redux/slice/ProductsApiSlice";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';


const NewCollection = () => {
  const { currency } = useSelector((state: RootState) => state.counterCurrency)
  const { data: products, isError, isLoading, isSuccess, error } = useGetRecentProductsQuery("productsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })


  let content

  if (isLoading) {
    content = (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (isError) content = <p>{products?.result?.message}</p>
  if (isSuccess) {
    content = (<section className="wrapper-newprice bgcolorgold bg-dotted-pattern bg-contain py-3 px-10 mt-4 font-display">
      <div className="flex justify-center py-1">
        <h5 className="text-4xl">Our New Collection</h5>
      </div>
      <div className="pt-3">
      <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={2000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          draggable
          focusOnSelect={false}
          infinite={true}
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1250
              },
              items: 5,
              partialVisibilityGutter: 40
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            },
            mobileAboveTabletBelow: {
              breakpoint: {
                max: 780, // This is the upper limit for the range
                min: 464,  // This is the lower limit for the range
              },
              items: 2, // Number of items to display in this range
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 780
              },
              items: 3,
              partialVisibilityGutter: 30
            },
            laptopMedium: {
              breakpoint: {
                max: 1250,
                min: 1024
              },
              items: 4,
              partialVisibilityGutter: 30
            }
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          slidesToSlide={1}
          swipeable
        >
          {products?.result?.map((newcollection: sareeData) => (
            <Card
              key={newcollection.id}
              className="w-[230px] max-sm:w-[310px]  2xl:w-[270px] xl:h-[400px] 2xl:h-[420px] h-[465px] mx-2 shadow-xl"
            >
              <Link to={`/newcollection/${newcollection.id}`}>
                <img
                  src={`${IMGURL}${newcollection.thumbnail}`}
                  alt={newcollection.productName}
                  /* width={200} 
                  height={150}*/
                  className="rounded-t-md w-full 2xl:h-[300px] xl:h-[280px] h-[350px] focus-visible:ring-0 focus:ring-0 "
                />

              </Link>
              <div className="flex justify-center  p-4 flex-col items-center gap-2">
                <p className="text-md font-medium line-clamp-2 overflow-ellipsis">{newcollection.productName}</p>
                <p className="text-md font-medium  flex gap-1 items-center">
                  {/*  {currency==="USD"? <><BsCurrencyDollar size={20} />{newcollection.priceUSD}</>:
                   <><MdCurrencyRupee size={20} />{newcollection.priceINR}</>} */}
                  {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(currency === "USD" ? newcollection.priceUSD : newcollection.priceINR)}
                </p>
              </div>
            </Card>
          ))}
        </Carousel>
        
      </div>
    </section>)
  }
  return <>{content}</>
};

// const NewCollection = () => {
//   const { currency } = useSelector((state: RootState) => state.counterCurrency)
//   const { data: products, isError, isLoading, isSuccess, error } = useGetRecentProductsQuery("productsList", {
//     pollingInterval: 60000,
//     refetchOnFocus: true,
//     refetchOnMountOrArgChange: true
//   })

//   const settings = {
//     infinite: true,
//     autoplay: true,
//     speed: 2000,
//     autoplaySpeed: 1000,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     pauseOnHover: true,
//   /*   prevArrow: <FaArrowLeft size={40} color="#570909" className="bg-white rounded-lg"/>,
//     nextArrow: <FaArrowRight size={40} color="#570909" />, */
//     prevArrow: <FaAngleLeft size={20} color="#570909" />,
//     nextArrow: <FaAngleRight size={48} color="#570909" />,
//     responsive: [
//       {
//         breakpoint: 1200,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 400,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };
//   let content

//   if (isLoading) {
//     content = (
//       <div className="flex justify-center items-center h-full">
//         <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
//       </div>
//     );
//   }

//   if (isError) content = <p>{products?.result?.message}</p>
//   if (isSuccess) {
//     content = (<section className="wrapper-newprice bgcolorgold bg-dotted-pattern bg-contain py-3 px-10 mt-4 ">
//       <div className="flex justify-center ">
//         <h5 className="text-4xl font-medium">Our New Collection</h5>
//       </div>
//       <div className="pt-3">
//         <Slider {...settings} className="flex gap-4 my-4">
//           {products?.result?.map((newcollection: sareeData) => (
//             <Card
//               key={newcollection.id}
//               className="w-[250px] xl:h-[400px] 2xl:h-[420px] h-[465px] mx-2 shadow-xl"
//             >
//               <Link to={`/newcollection/${newcollection.id}`}>
//                 <img
//                   src={`${IMGURL}${newcollection.thumbnail}`}
//                   alt={newcollection.productName}
//                   /* width={200} 
//                   height={150}*/
//                   className="rounded-t-md w-full 2xl:h-[300px] xl:h-[280px] h-[350px] focus-visible:ring-0 focus:ring-0 "
//                 />

//               </Link>
//               <div className="flex justify-center  p-4 flex-col items-center gap-2">
//                 <p className="text-md font-medium line-clamp-2 overflow-ellipsis">{newcollection.productName}</p>
//                 <p className="text-md font-medium  flex gap-1 items-center">
//                   {/*  {currency==="USD"? <><BsCurrencyDollar size={20} />{newcollection.priceUSD}</>:
//                    <><MdCurrencyRupee size={20} />{newcollection.priceINR}</>} */}
//                   {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(currency === "USD" ? newcollection.priceUSD : newcollection.priceINR)}
//                 </p>
//               </div>
//             </Card>
//           ))}
//         </Slider>
//       </div>
//     </section>)
//   }
//   return <>{content}</>
// };


export default NewCollection;

