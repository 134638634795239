import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Row } from "@tanstack/react-table";

import { Button } from "../../../../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/select";
import { OrderT, sareeData } from "@/react-app-env";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { useDeleteProductAMutation } from "../../../../../redux/slice/admin/AProductsApiSlice";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../ui/alert-dialog";
import { RiDeleteBin6Line } from "react-icons/ri";
import {  useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import {  useUpdateOrderCourierInfoMutation, useUpdateOrderStatusAMutation } from "../../../../../redux/slice/admin/ACreateOrderApiSlice";
import { cancelledStatuses, indianCourierServices, orderStatuses } from "../../../../../constants/index";
import { Input } from "../../../../ui/input";

interface DataTableRowActionsProps<TData> {
  row: Row<OrderT>;
  type?:string
}

export function DataTableRowActionsOrder<TData>({
  row,type
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const [updateorderStatus, { data, isSuccess }] = useUpdateOrderStatusAMutation();
  const [orderStatus, setOrderStatus] = useState<string>(order.orderStatus);
  const [open,setOpen]=useState(false)
  const handleClose=()=>{
    setOpen(false)
  }
  const status= type==="cancelled"?cancelledStatuses:orderStatuses
  
  const handleUpdateOrder = async () => {
    await updateorderStatus({ordStatus:orderStatus, ordId: order.id });
    setOpen(false)
  };
  const changeHandler = (value: string) => {
    setOrderStatus(value);
  };
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="text-sm font-normal">
          {order.orderStatus} <FaRegEdit size={16} className="pl-1" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change Order Status</AlertDialogTitle>
          <AlertDialogDescription>
            <Select value={orderStatus} onValueChange={changeHandler}>
              <SelectTrigger className="w-[60%] bg-white ring-1">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent className="bg-gray-100">
                {status?.map((status) => (
                  <SelectItem key={status.id} value={status.name}>
                    {status.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleUpdateOrder}>Update Status</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export function DataTableRowActionsCourier<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const [updateCourierStatus] = useUpdateOrderCourierInfoMutation();
  const [cName, setCName] = useState<string>("DTDC Express Limited");
  const [cBillNo, setCBillNo] = useState<string>("");
  const [open,setOpen]=useState(false)
  const handleClose=()=>{
    setOpen(false)
  }
  
  
  const handleUpdateCourierInfo = async () => {
   // console.log({courierName:cName, orderId: order.id,billNo:cBillNo});
    await updateCourierStatus({courierName:cName, orderId: order.id,billNo:cBillNo});
    setOpen(false)
  };
/*   const changeHandler = (value: string) => {
    setCName(value);
  }; */
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="text-sm font-normal">
          {order.courierName}{", "}{order.CourierBillNo} <FaRegEdit size={16} className="pl-1" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change/Enter Courier Info</AlertDialogTitle>
          <AlertDialogDescription className="flex flex-col gap-2">
            {/* <Select value={cName} onValueChange={changeHandler}>
              <SelectTrigger className="w-[60%] bg-white ring-1">
                <SelectValue placeholder="Courier Service" />
              </SelectTrigger>
              <SelectContent className="bg-gray-100">
                {indianCourierServices?.map((status) => (
                  <SelectItem key={status.id} value={status.name}>
                    {status.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select> */}
            <Input className="text-black" value={cName} onChange={(event) => setCName(event.target.value)} placeholder="Courier Service" />
            <Input className="text-black" value={cBillNo} onChange={(event) => setCBillNo(event.target.value)} placeholder="Courier Bill No" />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleUpdateCourierInfo}>Update/Add Courier Info</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function AlertDialogDemo({ id }: { id: number }) {
  const [deleteProductA, { isSuccess, data }] = useDeleteProductAMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && data?.success) {
      /*  navigate("/admin/dashboard/products") */
      window.location.reload();
    }
  }, [isSuccess, navigate, data]);

  const handleRemoveProduct = async () => {
    await deleteProductA(id);
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="pl-0">
          Delete <RiDeleteBin6Line size={18} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently remove the
            Product from app.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleRemoveProduct}>Continue</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

// interface DataTableRowActionsProps<TData> {
//     row: Row<sareeData>;
//   }

//   export function DataTableRowActions<TData>({
//     row,
//   }: DataTableRowActionsProps<TData>) {
//     const product = row.original;
//     const navigate = useNavigate();
//     const [deleteProductA, { isSuccess, isError, data }] = useDeleteProductAMutation();
//     const [isAlertDialogOpen,setIsAlertDialogOpen]=useState(false)

//     const handleDeleteClick = () => {
//       // Open the alert dialog directly when "Delete" is clicked
//       setIsAlertDialogOpen(true);
//     };

//     return (
//       <DropdownMenu>
//         <DropdownMenuTrigger asChild>
//           <Button
//             variant="ghost"
//             className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
//           >
//             <DotsHorizontalIcon className="h-4 w-4" />
//             <span className="sr-only">Open menu</span>
//           </Button>
//         </DropdownMenuTrigger>
//         <DropdownMenuContent align="end" className="w-[160px]">
//           <DropdownMenuItem onClick={() => navigate(`/admin/dashboard/edit/${product.id}`)}>
//             Edit<FiEdit className="w-16 " size={20} />
//           </DropdownMenuItem>
//           <DropdownMenuItem onClick={() => navigate(`/admin/dashboard/view/${product.id}`)}>View Product</DropdownMenuItem>
//           <DropdownMenuItem onClick={() => handleDeleteClick()}>
//             Delete
//             <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
//           </DropdownMenuItem>
//         </DropdownMenuContent>

//         {/* Render the alert dialog directly */}
//         {isAlertDialogOpen && (
//           <AlertDialogDemo id={product.id} onClose={() => setIsAlertDialogOpen(false)} />
//         )}
//       </DropdownMenu>
//     );
//   }

//   export function AlertDialogDemo({ id, onClose }: { id: number; onClose: () => void }) {
//     const [deleteProductA, { isSuccess, isError, data }] = useDeleteProductAMutation();

//     const handleRemoveProduct = async () => {
//       await deleteProductA(id);
//       onClose();
//     };

//     return (
//       <AlertDialog>
//         <AlertDialogContent>
//           <AlertDialogHeader>
//             <AlertDialogTitle>Are you sure?</AlertDialogTitle>
//             <AlertDialogDescription>
//               This action cannot be undone. This will permanently remove the Product from the Web.
//             </AlertDialogDescription>
//           </AlertDialogHeader>
//           <AlertDialogFooter>
//             <AlertDialogCancel>Cancel</AlertDialogCancel>
//             <Button onClick={handleRemoveProduct}>Continue</Button>
//           </AlertDialogFooter>
//         </AlertDialogContent>
//       </AlertDialog>
//     );
//   }
