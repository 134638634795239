import { AlertChangePwd, AlertUpdateUser, CustomerAddressInfo } from "../components/shared/Detail/CustomerDetails"
import { useGetCustomerQuery } from "../redux/slice/UserApiSlice"


const CustomerDetails = () => {
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!)
  const {data:user,refetch}=useGetCustomerQuery(currentUser?.refId)
  
  return (<div className="bgcolorgold">
    <div className="wrapper">
      <h5 className="h5-bold">Customer Details:</h5>
      <div className="my-4 wrapper-weaves flex-col flex gap-4 md:justify-between">
        <div className="flex gap-3 items-center ">
        <p className="text-md font-medium">FullName:{"  "}{user?.result?.fullName}</p>
        </div>
        <div className="flex gap-3 items-center ">
        <p className="text-md font-medium">Mobile:{"  "}{user?.result?.mobile}</p>
        </div>
        <div className="flex gap-3 items-center ">
        <p className="text-md font-medium">Email:{"  "}{user?.result?.email}</p>
        </div>
        <div className="flex gap-2">
        {user?.result&& <AlertUpdateUser user={user?.result}/>}
        {user?.result&& <AlertChangePwd user={user?.result}/>}
        </div>
      </div> 
      <div className="my-4 wrapper-weaves  flex-col flex gap-5">
      <p className="font-semibold text-lg">Address:</p>
      <div className="mx-4 flex flex-col gap-4">
      {user?.result?.address?.length>0&&<div className="flex flex-col gap-4">
        {user?.result?.address.map((add: any, idx: number) =>{
           const { hno, street, city, pincode, state, country, fullName,mobile } =JSON.parse(add?.address)
         return <div className="flex gap-4" key={add?.id}>
           <p className="text-md font-medium">
           {fullName}, {mobile}, {hno}, {street}, {city}, {pincode}, {state}, {country}
           </p>
          <CustomerAddressInfo reftechUser={refetch} type="Edit" address={add?.address} id={add?.id} customerId={add?.customerId}/>
           </div>    
        })}
        </div>}
        <CustomerAddressInfo reftechUser={refetch} type="Create" customerId={user?.result?.id}/>
        </div>
      </div>
    </div>
  </div>
  )
}



export default CustomerDetails