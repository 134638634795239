import { MdAccountCircle } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { IoVideocam } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { US,IN } from 'country-flag-icons/react/3x2'
import { NavLink, useNavigate } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { RootState } from "../../../redux/store";
import { changeCurrency } from "../../../redux/features/currencySlice";
import { Sheet, SheetContent, SheetTrigger } from "../../ui/sheet";
import { Separator } from "@radix-ui/react-select";
import { Button } from "../../ui/button";
import { useGetCartCountByCustomerQuery } from "../../../redux/slice/CartApiSlice";
import { useGetWishlistCountByCustomerQuery } from "../../../redux/slice/Wishlist";



const IconsHeader = () => {
  const { cartItems } = useSelector((state: RootState) => state.counterCart);
  const { wishlist } = useSelector((state: RootState) => state.counterWishlist);
  const {currency} = useSelector((state: RootState)=>state.counterCurrency)
  const currentUser=JSON.parse(localStorage.getItem("cpauser")!);
  const { data: cartItemsCountApi, isError, isLoading, isSuccess, error} = useGetCartCountByCustomerQuery(currentUser?.refId)
  const { data: wishlistCountApi} = useGetWishlistCountByCustomerQuery(currentUser?.refId)
  const cartItemsCount =cartItemsCountApi?.result?.cartCount>0?cartItemsCountApi?.result?.cartCount : cartItems.reduce((acc, item) => acc + item.count, 0);
  const wishlistCount =wishlistCountApi?.result?.wishListCount>0? wishlistCountApi?.result?.wishListCount: wishlist.length;
  
  const dispatch=useDispatch()
  const handleCurrencyChange = (value:string) => {
    dispatch(changeCurrency(value));
  };
  return (
    <>
      <div>
        <Select value={currency==="USD"?"USD":"INR"} onValueChange={handleCurrencyChange}>
          <SelectTrigger className="w-[70px] h-6">
            <SelectValue placeholder="currency" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="INR" ><IN title="India" className="w-6 h-6 inline mx-1"/>INR</SelectItem>
            <SelectItem value="USD" ><US title="United States" className="w-6 h-6 inline mx-1"/>USD</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <NavLink to="/explore" className="max-md:hidden">
        <IoVideocam size={28} />
      </NavLink>
      <div className="relative">
        <NavLink to="/wishlist">
          <FaRegHeart size={28} />{" "}
          {wishlistCount > 0 && (
            <p className="absolute top-0 right-0 shadow-xl bg-red-500 p-[1px] px-[4px] rounded-full text-xs">
              {wishlistCount}
            </p>
          )}
        </NavLink>
      </div>
      <div className="relative">
        <NavLink to="/cart">
          <HiOutlineShoppingBag size={28} />{" "}
          {cartItemsCount > 0 && (
            <p className="absolute top-0 right-0 shadow-xl bg-red-500 p-[1px] px-[4px] rounded-full text-xs">
              {cartItemsCount}
            </p>
          )}
        </NavLink>
      </div>
      {!currentUser || !currentUser?.token  ? (
        <NavLink to="/signin"> 
          <MdAccountCircle size={28} />
        </NavLink>
      ) : (
        <UserIcon />
      )}
    </>
  );
};

export default IconsHeader;


export const  UserIcon= () => {
  const navigate=useNavigate()
  const currentUser=JSON.parse(localStorage.getItem("cpauser")!);
  const handleSignout=async()=>{
    localStorage.removeItem("cpauser")
    navigate("/signin"); 
    window.location.reload();
  }

  return (
    <nav className="sticky top-0">
   <Sheet key="right" >
  <SheetTrigger className="align-middle">
    <MdAccountCircle size={28} />
  </SheetTrigger>
 <SheetContent className="flex flex-col gap-6 bg-white" side="right">
    {/* <img 
      src="/assets/logo/logo_english.png"
      alt="logo"
      width={128}
      height={38}
    /> */}
    <p className="text-lg font-semibold">Customer Details</p>
    <Separator className="border border-gray-100" />
    <div className="flex flex-col gap-4 text-md font-semibold">
    {currentUser?.token &&(<>
    <div className="flex justify-between">
      <p>Fullname:</p>
      <p>{currentUser?.fullName}</p>
    </div>
    <div className="flex justify-between">
      <p>Mobile:</p>
      <p>{currentUser?.userName}</p>
    </div>
    <Separator className="border border-gray-100" />
    <NavLink to="/myorders" className="text-md font-bold text-red-800">My Orders</NavLink>
    <Separator className="border border-gray-100" />
    </>)}
    <div className="flex justify-between mt-8">
     <NavLink to="/userdetails">Edit Details</NavLink>
     <Button onClick={handleSignout}>SignOut</Button>
    </div>
    </div>
  </SheetContent>
</Sheet>
  </nav>
  );
};

 