import * as React from "react";

import { cn } from "../../../lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../../ui/navigation-menu";
import { subHeaderLinks } from "../../../constants/index";
import { Link } from "react-router-dom";

export function SubNavigation() {
    return (
      <NavigationMenu>
        <NavigationMenuList className="flex lg:flex-row flex-wrap flex-col">
          {subHeaderLinks.map((links) => {
            return (
              <React.Fragment key={links.label}>
               {/*  {links.subroutes ? (
                  <NavigationMenuItem className="relative">
                    <NavigationMenuTrigger className="hover:text-red-400 md:text-white">
                      {links.label}
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <div className=" grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                        {links.subroutes?.map((link) => (
                          <ListItem
                            key={`${links.label}-${link.label}`}
                            href={link.route}
                          >
                            {link.label}
                          </ListItem>
                        ))}
                      </div>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                ) : ( */}
                  <NavigationMenuItem key={links.route}>
                    <Link to={links.route} className={`${navigationMenuTriggerStyle()} hover:text-red-400 lg:text-white`}> {links.label}
                     {/*  <NavigationMenuLink href={links.route}
                        className={`${navigationMenuTriggerStyle()} hover:text-red-400 lg:text-white`}
                      >
                        {links.label}
                      </NavigationMenuLink> */}
                    </Link>
                  </NavigationMenuItem>
               {/*  )} */}
              </React.Fragment>
            );
          })}
        </NavigationMenuList>
      </NavigationMenu>
    );
  }


const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})

ListItem.displayName = "ListItem"