import {  Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/redux/store'
import FSTableData from '../components/shared/Table/FSTableData'
import { useGetAllProductsMutation } from '../redux/slice/ProductsApiSlice'
import { useEffect } from 'react'
import { useGetSubcategoryQuery } from '../redux/slice/CategoryApiSlice'
import { weavesData } from '../constants/index'
import { SlashIcon } from "@radix-ui/react-icons"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb"
import { sareeData } from '@/react-app-env'


const SareesByCategory = () => {
  const { currency } = useSelector((state: RootState) => state.counterCurrency);
  const { catId } = useParams();
  const [productByCategory, {isLoading,isSuccess,isError,error,data:products }] = useGetAllProductsMutation()
  const {data:subCategories} = useGetSubcategoryQuery(catId)
  const headerName = weavesData.filter(item=>item.categoryId===Number(catId))[0].name
 
  
  useEffect(()=>{
    productByCategory({catId,pageNumber:0})
  },[productByCategory,catId])


  let content
  if (isLoading) {
    content = (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
}
  if (isError) content = <p>{products?.result?.message}</p>
  if (isSuccess&& products?.result?.length>0){
    content=  <>
    <BreadcrumbWithCustomSeparator data = {products?.result[0]} />
    <FSTableData data={products?.result} subCategories={subCategories?.result} categoryType={headerName}/>
    </>
  }
  if (isSuccess&&!products?.result?.length){
    content= <section className="bgcolorgold" >
    <p className="p-4 text-center text-lg font-semibold">
      No Sarees Available Under This Category 
    </p></section>}
  return (<section className="bgcolorgold" >
    <div>
    {content}
    </div>
    </section>);
};


export function BreadcrumbWithCustomSeparator({data}:{data: sareeData}) {
  return (
    <Breadcrumb className='h5-bold  sm:m-2 md:my-4 md:px-72'>
      <BreadcrumbList>
        <BreadcrumbItem>
          {/* <BreadcrumbLink href="/">Home</BreadcrumbLink> */}
          <Link to="/">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>
        <BreadcrumbItem>
          <BreadcrumbPage className='font-bold text-lg'>{data.categoryName}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}
export default SareesByCategory;

