
const ShippingPolicy = () => {
  return (
    <div className="bgcolorgold">
      <div className="wrapper flex flex-col gap-4">
        <div>
          <h5 className="h5-bold">SHIPPING POLICY</h5>
        </div>
        <div>
          <p className="p-semibold-20">Processing Time:</p>
          <p>
          It takes around 7-10 business days for the delivery. Please bear
              with us, we will always try to deliver your order at the earliest.
          </p>

        </div>
        <div>
          <p className="p-semibold-20">Shipping Methods::</p>
          <p>
          We offer standard shipping and express shipping.
Shipping rates and delivery times vary based on the selected shipping method and destination.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Shipping Address:</p>
          <p>
          Please ensure that the shipping address provided at checkout is accurate and complete.
We are not responsible for orders shipped to incorrect or incomplete addresses provided by the customer.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Delivery Issues: </p>
          <p>
            In the event of delivery issues such as lost or damaged packages, please contact our customer support team for assistance.
We will work with the shipping carrier to resolve any delivery issues and ensure a satisfactory outcome for our customers.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Policy Changes:</p>
          <p>
          We reserve the right to modify or update our shipping policy at any time without prior notice.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
