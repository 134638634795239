
const Stores = () => {
  return (
    <div className="bgcolorgold">
      <div className="wrapper flex flex-col gap-4">
        <p className="p-semibold-20">Visit Us:</p>
        <p className="p-regular-16">
          Visit our store at
          Gottipati Towers, Plots 1768/1769, Block Srinivasa Avenue, 3rd Layout,
          Pragathi Nagar, Kukatpalli, Hyderabad, Telangana, 500047. or explore
          our curated collection online at
          www.chennapatnampattucheeralaangadi.com Indulge in the luxury of silk
          at Chennapatnam Pattu Cheerala Angadi – where tradition meets
          elegance.
        </p>
      </div>
    </div>
  );
};

export default Stores;
