import { Separator } from "../../components/ui/separator"

const productdata=[
    {id:1,name:"Products:",data:[{qst:"Where to buy sarees:",ans:"Visit the chennapatnam website and filter the prodducts depending on category/price range, add to cart and proceed to buy"}]},
    {id:1,name:"Order:",data:[{qst:"Order Status:",ans:"Once the order placed it takes 7-8 working days to deliver the order"}]},
    {id:1,name:"Payment:",data:[{qst:"How to make a payment:",ans:"The payment can be done through the razorpay"},{qst:"Payment Method:",ans:"It accepts the all online payment methods"}]},
]

const FAQ = () => {
  return (
    <div className="bgcolorgold">
        <div className="wrapper">
            <div className="wrapper-weaves">
                <h5 className="h5-bold">FREQUENTLY ASKED QUESTIONS</h5>
            </div>
            <Separator />
            <div className="flex flex-col gap-2">
               {productdata.map(question=>(
                <div className="flex flex-col gap-2">
                    <h6 className="text-lg font-semibold">{question.name}</h6>
                    {question.data.map(dat=>(
                        <div className="wrapper-weaves">
                        <p className="text-md font-medium">{dat.qst}</p>
                        <p>{dat.ans}</p>
                        </div>
                    ))}
                </div>
               ))}
            </div>
        </div>
    </div>
  )
}

export default FAQ
