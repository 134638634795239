import { useRouteError } from 'react-router-dom';
interface MyRouteError {
    status: number;
    data: {
      message: string;
      // Add other properties based on the actual structure
    };
  }
function ErrorPage() {
  const error = useRouteError() as MyRouteError;

  let title = 'An error occurred!';
  let message = 'Something went wrong!';

  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = 'Not found!';
    message = 'Could not find resource or page.';
  }

  return (
    <>
      {/* <MainNavigation />
      <PageContent title={title}> */}
        <p>{message}</p>
      {/* </PageContent> */}
    </>
  );
}

export default ErrorPage;
