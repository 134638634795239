import { apiSlice } from "../../apiSlice";


export const AImagesUploadApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addProductImagesA: builder.mutation({
            query: initialProdData => ({
                url: '/admin/addProductImage',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Images', id: "LIST" }
            ]
        }),
        deleteProductImagesA: builder.mutation({
            query: ( id ) => ({
                url: `/admin/deleteProductImage/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Images', id: arg.id }
            ]
        }),
    }),
})

export const {
    useAddProductImagesAMutation,
    useDeleteProductImagesAMutation
} = AImagesUploadApiSlice

