import React from "react";
import { Input } from "../../ui/input";
import { IoSearch } from "react-icons/io5";
//import { usePathname, useRouter, useSearchParams } from "next/navigation";


const Search = ({ placeholder="Find Your Saree..." }: { placeholder?: string }) => {
    //const [query,setQuery]=useState("")
 /*   const searchParams=useSearchParams()
  const router =useRouter()
   const pathname = usePathname();
  const { replace } = useRouter();

   useEffect(() => {
        const delayDebounceFn = setTimeout(() => {    
            let newUrl = '';
    
           if(query) {
            newUrl = formUrlQuery({
              params: searchParams.toString(),
              key: 'query',
              value: query
            })
          } else {
            newUrl = removeKeysFromQuery({
              params: searchParams.toString(),
              keysToRemove: ['query']
            })
          } 
    
          router.push(newUrl, { scroll: false });
        }, 300)
    
        return () => clearTimeout(delayDebounceFn);
      }, [query,pathname, searchParams, router,replace]) 
    
    const handleSearch = useDebouncedCallback((query) => {

       
        const params = new URLSearchParams(searchParams);
        if (query) {
          params.set('query', query);
        } else {
          params.delete('query');
        }
        replace(`${pathname}?${params.toString()}`,{ scroll:false });
      }, 300); */
      const handleSearch=(query:string)=>{
        console.log(query);
        
      }
    return <div className="flex-center max-h-[52px] min-h-[32px] w-full overflow-hidden md:rounded-full rounded-lg md:bg-red-200 bg-gray-100 px-4 py-2">
        <IoSearch size={24} className="max-md:hidden"/>
        <Input type="text"
        placeholder={placeholder}
        onChange={e=>handleSearch(e.target.value)}
        className="p-regular-16 border-0  md:bg-red-200 bg-gray-100 placeholder:text-gray-500 focus:border-0  focus-visible:ring-0 focus-visible:ring-offset-0"
        />
        </div>;
};

export default Search;
