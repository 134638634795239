

import React, { useEffect, useRef } from 'react';



const Video = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    const playVideo = () => {
      if (video) {
        video.play().catch(error => {
          console.error('Auto-play was prevented:', error);
        });
      }
    };
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        playVideo();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    playVideo();
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <video
      ref={videoRef}
      width="100%"
      style={{ maxWidth: '100%'}}
      autoPlay
      loop
      muted
      playsInline
    >
      <source src="/assets/CPCA-video.mp4" type="video/mp4" />
    </video>
  );
};

export default Video;







