import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import CartDetails, { CartDetailsAPI } from "../components/shared/Detail/CartDetails";
import { cartProduct } from "@/react-app-env";
import { useGetWishlistByCustomerMutation } from "../redux/slice/Wishlist";
import { useEffect, useState } from "react";

const Wishlist = () => {
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const { wishlist } = useSelector((state: RootState) => state.counterWishlist);
  const [state,setState]=useState<number>(1)
  const [wishlistGetData,{ data: whishlistApi, isError, isLoading, isSuccess, error }] = useGetWishlistByCustomerMutation()

useEffect(()=>{
  wishlistGetData({customerId:currentUser?.refId,pageNumber:0})
},[currentUser?.refId,wishlistGetData,state])

  return (
    <div className="bgcolorgold">
      <div className="wrapper-weaves my-4">
        <div className="flex md:justify-between md:flex-row flex-col gap-4 ">
          <div className="shadow-lg bg-gray-200  px-2 w-full">
            <h5 className="h5-bold">Wishlist Products</h5>
            <div className="flex justify-between px-2 items-center border-b-2 pb-3 border-gray-200">
              <p>Your Items</p>
              <p>price</p>
            </div>
            <div className="p-2 flex flex-col gap-3">
              {whishlistApi?.result?.length > 0 ? whishlistApi?.result?.map((item: cartProduct) => (
                <CartDetailsAPI key={item.id} saree={item} type="WishList" setState={setState}/>
              )) : (wishlist.length > 0 ? (
                wishlist.map((item: any) => (
                  <CartDetails key={item.id} saree={item} type="WishList" />
                ))
              ) : (
                <p className="text-center text-lg font-semibold">No Wishlist Items Found</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
