import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import IconsHeader from "./IconsHeader";
import MobileNav from "./MobileNav";
import SubHeader from "./SubHeader";


const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`w-full set-wrap top-0 z-50 font-display text-white bg-opacity-0 ${isSticky ? 'headersticky headermenu ' : 'headerabsolute'}` } >
      <div className="flex-between lg:gap-4 w-full ">
          <MobileNav/>
        <NavLink to="/">
       {!isSticky? <img
          alt="logo"
          src="/assets/logo/CPALogoEnglish.png"
        /* className="md:h-[100px] md:w-56 w-16 h-[48px]" */
        className="md:w-28 w-16"
        />:
        <img
          alt="logo"
          src="/assets/logo/logo_english.png"
          className="w-28"
        />}</NavLink>
        {/* <div></div> */}
        <SubHeader/>
        
        <div className="flex justify-end gap-5 items-center text-white">  
        <IconsHeader/>
        </div>
      </div>
    </header>
  );
};

export default Header;


