import { ColumnDef } from "@tanstack/react-table"
import { DataTableColumnHeader } from "./data-table-column-header"
import { DataTableRowActions } from "./data-table-row-actions"
import { sareeData } from "@/react-app-env"
import { IMGURL } from "../../../../../constants/appConfig"
import ReactHtmlParser from 'html-react-parser';


export const columns: ColumnDef<sareeData>[] = [

  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Id" />
    ),
    cell: ({ row }) => {
      const product = row.original
    return <div className="w-[80px]">{product.id}</div>
  },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "thumnail",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Thumbnail" />
    ),
    cell: ({ row }) => {
      const product = row.original
    return  <div className="capitalize">
        <img src={`${IMGURL}${product.thumbnail}`} alt="imag" width={30} height={20} className="rounded-sm"/>
      </div>
    },
  },
  {
    accessorKey: "subcategoryName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Subcategory" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "productName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ProductName" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  /* {
    accessorKey: "descriptionShort",
    header:  ({ column }) => (
      <DataTableColumnHeader column={column} title="DescriptionShort" />
    ),
    cell: ({ row }) => (
      <div className="capitalize w-[150px]">{row.getValue("descriptionShort")}</div>
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  }, */
  {
    accessorKey: "productDescription",
    header:  ({ column }) => (
      <DataTableColumnHeader column={column} title="Description" />
    ),
    cell: ({ row }) => (
      <div className="capitalize w-[150px]  overflow-hidden overflow-ellipsis whitespace-pre-line leading-[1.2em] max-h-[3.6em]">{ReactHtmlParser(row.getValue("productDescription"))}</div>
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "priceINR",
    header: () => <div className="text-right">PriceINR</div>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("priceINR"));
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "INR",
      }).format(amount);
      return <div className="text-right font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "priceUSD",
    header: () => <div className="text-right">PriceUSD</div>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("priceUSD"));

      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);
      
      return <div className="text-center font-medium">{(formatted==="$NaN")?"-":formatted}</div>;
    },
  },
  {
    accessorKey: "colorCode",
    header: "Color",
        cell: ({ row }) => (
      <div className="w-[30px] h-[30px] rounded-full" style={{backgroundColor:row.getValue("colorCode")}}></div>
    ),
  },
  {
    accessorKey: "availableQuantity",
    header: "AvailableQuantity",
    cell: ({ row }) => (
      <div className="text-center">{row.getValue("availableQuantity")}</div>
    ),
  },
  {
    accessorKey: "soldQuantity",
    header: "SoldQuantity",
    cell: ({ row }) => (
      <div className="text-center">{row.getValue("soldQuantity")}</div>
    ),
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
