import {  Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/redux/store'
import FSTableData from '../components/shared/Table/FSTableData'
import { useGetAllProductsMutation } from '../redux/slice/ProductsApiSlice'
import { useEffect } from 'react'
import { useGetAllSubcategoryQuery, useGetSubcategoryQuery } from '../redux/slice/CategoryApiSlice'
import { SlashIcon } from "@radix-ui/react-icons"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb"
import { sareeData } from '@/react-app-env'




const SareesBySubCategory = () => {
  const { currency } = useSelector((state: RootState) => state.counterCurrency);
  const { subcatId } = useParams();
  const [productByCategory, {isLoading,isSuccess,isError,error,data:products }] = useGetAllProductsMutation()
  const {data:AllSubCategories}=useGetAllSubcategoryQuery("subCategoriesList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,})
  const headerName =AllSubCategories?.result.length>0&&AllSubCategories?.result.filter((item:any)=>item.id===Number(subcatId))[0].subcategoryName

  useEffect(()=>{
    productByCategory({subcatId})
  },[productByCategory,subcatId])

  console.log(products);
  

  let content
  if (isLoading) {
    content = (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
}
  if (isError) content = <p>{products?.result?.message}</p>
  if (!products?.result?.length){
    content= <section className="bgcolorgold" >
    <p className="p-4 text-center text-lg font-semibold">
      No Sarees Available Under This {headerName}
    </p></section>}
  if (isSuccess&& products?.result?.length>0){
    content=  <>
    {/* <h5 className='h5-bold  sm:m-2 md:px-72'>{headerName}</h5> */}
    <BreadcrumbWithCustomSeparator data = {products?.result[0]} />
    <FSTableData data={products?.result} /*subCategories={subCategories?.result}  categoryType={headerName} *//>
    </>
  }

  return (<section className="bgcolorgold" >
    <div>
    {content}
    </div>
    </section>);
};


export function BreadcrumbWithCustomSeparator({data}:{data: sareeData}) {
  return (
    <Breadcrumb className='h5-bold  sm:m-2 md:my-4 md:px-72'>
      <BreadcrumbList>
        <BreadcrumbItem>
          {/* <BreadcrumbLink href="/">Home</BreadcrumbLink> */}
          <Link to="/">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>
        <BreadcrumbItem>
          {/* <BreadcrumbLink href={`/category/${data.categoryId}`}>{data.categoryName}</BreadcrumbLink> */}
          <Link to={`/category/${data.categoryId}`}>{data.categoryName}</Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>
        <BreadcrumbItem>
          <BreadcrumbPage className='font-bold text-lg'>{data.subcategoryName}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}


export default SareesBySubCategory;

