
import { apiSlice } from "../apiSlice";


export const wishlistApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addItemWishlist: builder.mutation({
            query: initialCartData => ({
                url: '/common/addItemWishlist',
                method: 'POST',
                body: {
                    ...initialCartData,
                }
            }),
            invalidatesTags: [
                { type: 'Wishlist', id: "LIST" }
            ]
        }),
        deleteItemWishlist: builder.mutation({
            query: ( id ) => ({
                url: `/common/deleteItemWishlist/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Wishlist', id: arg.id }
            ]
        }),
        getWishlistCountByCustomer: builder.query({
            query: (id) => ({ url: `/common/getWishlistCountByCustomer/${id}` }),
            providesTags: (result, error, arg) => {
              if (result?.ids) {
                return [
                  { type: "Wishlist", id: "LIST" },
                  ...result.ids.map((id: any) => ({ type: "Wishlist", id })),
                ];
              } else return [{ type: "Wishlist", id: "LIST" }];
            },
          }),
          getWishlistByCustomer: builder.mutation({
            query: initialData => ({ url: `/common/getWishlistByCustomer`,method:"POST",body:{...initialData} }),
            invalidatesTags: [ { type: "Wishlist", id: "LIST" },],   
          }),
    }),
})

export const {
    useAddItemWishlistMutation,
    useDeleteItemWishlistMutation,
    useGetWishlistByCustomerMutation,
    useGetWishlistCountByCustomerQuery
} = wishlistApiSlice

