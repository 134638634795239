import { apiSlice } from "../apiSlice";


export const RazorPayApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createOrderRazorPay: builder.mutation({
            query: orderDetails => ({
                url: '/common/createOrderRazorPay',
                method: 'POST',
                body: {
                    ...orderDetails,
                }
            }),
            invalidatesTags: [
                { type: 'Razorpay', id: "LIST" }
            ]
        }),
        RazorPayValidateData: builder.mutation({
            query: validateDetails => ({
                url: '/common/validateOrderRazorPay',
                method: 'POST',
                body: {
                    ...validateDetails,
                }
            }),
            invalidatesTags: [
                { type: 'Razorpay', id: "LIST" }
            ]
        }),
        
    }),
})

export const {
    useCreateOrderRazorPayMutation,
    useRazorPayValidateDataMutation
} = RazorPayApiSlice

