import Header from "../components/shared/RootLayout/Header";
import { Outlet } from "react-router-dom";
import Footer from "../components/shared/RootLayout/Footer";


const MainNavigation = () => {
  return (
    <div className="flex flex-col relative ">
      <Header />
      <Outlet />
      <Footer/>
    </div>
  );
};

export default MainNavigation;
