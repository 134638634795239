import { useGetAdminDashboardDataQuery } from "../../redux/slice/admin/ADashboardApiSlice";
import { Card } from "../../components/ui/card";

export default function HomeAdmin() {
  const {data:getDashboardData}=useGetAdminDashboardDataQuery('dashboardsList',{pollingInterval:6000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const result = getDashboardData?.result

  
  const data = [
    {id:1,
      title: "Total products",
      name:"totalProducts",
      count: 5,
      image:
        "/assets/dashboard/products.png",
    },
    {id:2,
      title: "Total coustmers",
      name:"totalCustomers",
      count: 5,
      image:
        "/assets/dashboard/customers.png",
    },
    {id:3,
      title: "Total Orders",
      name:"totalOrders",
      image:
        "/assets/dashboard/totalOrders.png",
    },
    {id:4,
      title: "Active Orders",
      name:"activeOrders",
      image:
        "/assets/dashboard/activeOrders.png",
    },
    {id:5,
      title: "Cancelled Orders",
      name:"cancelledOrders",
      image:
        "/assets/dashboard/cancelledOrders.png",
    },
    {id:6,
      title: "Delivered Orders",
      name:"deliveredOrders",
      image:
        "/assets/dashboard/deliveredOrders.png",
    },
  ];
  /* const status = [
    {id:1,
      title: "Total Orders",
      name:"totalOrders",
      image:
        "https://6valley.6amtech.com/public/assets/back-end/img/pending.png",
    },
    {id:2,
      title: "Active Orders",
      name:"activeOrders",
      image:
        "https://6valley.6amtech.com/public/assets/back-end/img/confirmed.png",
    },
    {id:3,
      title: "Cancelled Orders",
      name:"cancelledOrders",
      image:
        "https://6valley.6amtech.com/public/assets/back-end/img/packaging.png",
    },
    {id:4,
      title: "Delivered Orders",
      name:"deliveredOrders",
      image:
        "https://6valley.6amtech.com/public/assets/back-end/img/out-of-delivery.png",
    },
  ]; */
  return (
    <div className="wrapper-dashboard bg-gray-50">
        <div>
          <h5 className='h5-bold'>Dashboard</h5>
          <span className="text-gray-500 ">Welcome to Dashboard.</span>
        </div>
      <div className="my-6 py-4 shadow-lg border-2 flex-col flex gap-8 border-gray-200 bg-purple-200">
      <div className="mx-2 p-4 flex flex-wrap gap-6 justify-between">
              {data?.map((x) => {
                return (
                  <Card key={x.id} className="w-[320px] h-[130px] shadow-lg p-3 bg-purple-50">
                      <div className="flex justify-between">
                        <p className="text-orange-600 font-medium text-md">{x.title}</p>
                        <img src={x?.image} alt="img" width={70} height={70}/>
                      </div>
                      <div className="text-orange-600 font-medium text-md">
                      {result&&result[x.name!]}
                      </div>
                  </Card>
                );
              })}
       </div>
      {/* <div className="mx-2 p-4 flex flex-wrap gap-2 justify-between">
              {status?.map((x) => {
                return (
                  <Card key={x.id} className="w-[260px] p-3 py-5 border-none bg-gray-200 hover:shadow-xl ">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-3">
                        <img src={x?.image} alt="img"  width={30} height={30}/>
                       <p className="text-blue-800 font-medium text-md">{x.title}</p>
                        </div>
                        <p className="text-blue-800 font-medium text-md">{result&&result[x.name]}</p>
                      </div>
                  </Card>
                );
              })}
       </div> */}
      </div>
    </div>
  );
}
