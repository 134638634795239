import { OrderT } from "@/react-app-env";
import { useGetOrdersByCustomerQuery } from "../redux/slice/admin/ACreateOrderApiSlice";
import { Separator } from "../components/ui/separator";
import OrdersDetails from "../components/shared/Detail/OrdersDetails";

const MyOrders = () => {
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const {data: orders,isError,isLoading,isSuccess} = useGetOrdersByCustomerQuery(currentUser?.refId);
  console.log(orders);

  let content
  if (isLoading)   { content = (
    <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
);}
  if (isError) content = <p>{orders?.result?.message}</p>
  if (!orders?.result?.length){
    content= <div>
    <p className="p-4 text-center text-lg font-semibold">
      There Is No Orders You Have Placed
    </p></div>}
  if (isSuccess&& orders?.result?.length>0){
    content= <> 
    <div className="flex flex-col gap-2 md:my-2">
    <h5 className="h5-bold">Your Orders</h5>
    <p className="text-sm font-medium">Orders</p>
  </div>
  <Separator className="border border-gray-300" />
  <div className="p-2 flex flex-col gap-6">
    {orders?.result.map((order:OrderT)=><OrdersDetails key={order.id} order={order} />)}
  </div></> 
  }


  return (
    <div className="bgcolorgold">
      <div className="wrapper my-4">
       {content}
      </div>
    </div>
  );
};

export default MyOrders;
