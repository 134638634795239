import { NavLink } from "react-router-dom";
import IconsHeader from "../RootLayout/IconsHeader";
import MobileNav from "../RootLayout/MobileNav";
import SubHeader from "./SubHeader";


const Header = () => {
  return (
    <header className="w-full headermenu headersticky top-0 z-50 font-display" >
      <div className="flex-between set-wrap">
          <MobileNav/>
          <NavLink to="/" className="w-28">
        <img
          alt="logo"
          src="/assets/logo/logo_english.png"
        /></NavLink>
        <SubHeader/>
        <div className="flex justify-end gap-5 items-center text-white">  
        <IconsHeader/>
        </div>
      </div>
    </header>
  );
};

export default Header;


