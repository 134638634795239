


const Payment = () => {

  return (<div className="bgcolorgold py-8" >
    <div className="border-2 border-gray-500 my-2 md:p-4 flex flex-col md:flex-row md:justify-between gap-2 wrapper">
     <div className="text-green-700 text-xl font-semibold"> Thank You For Purchase. Your Order Got Placed Successfully.</div> 
    </div>
  </div>

  )
}

export default Payment