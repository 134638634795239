export const APIURL= "https://vilacampus.in/apicpa/"
export const IMGURL= "https://vilacampus.in/apicpa/static/"

/* export const APIURL= "http://localhost:3500/apicpa/"
export const IMGURL= "http://localhost:3500/apicpa/static/"  */

// export const APIURL= "https://chennapatnampattucheeralaangadi.com/apicpa/"
// export const IMGURL= "https://chennapatnampattucheeralaangadi.com/apicpa/static/"

export const RECAPCHASITEKEY= "6LegAHwpAAAAAF862qcoUDVCUVzHWD2ZyH1kAdn4"
export const RECAPCHASECRETKEY= "6LegAHwpAAAAANpkP4B5dNz0mTZRYi2jEWUIsh4b"
export const CALENDLYURL= "https://calendly.com/santhoshdasari543/chennapatnam"